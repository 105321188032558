<!-- ##### Search Wrapper Start ##### -->

<div class="search-wrapper d-flex align-items-center justify-content-center bg-img foo-bg-overlay" style="background-image: url('assets/img/townhall.png');">
    <div class="close--icon">
        <i class="fa fa-times"></i>
    </div>
    <!-- Logo -->
    <a href="" class="search-logo"><img src="" alt=""></a>
    <!-- Search Form -->
    <div class="search-form">
        <form action="#" method="get">
            <input type="search" name="search" id="search" placeholder="Enter Your Keywords">
            <button type="submit"><i class="fa fa-search" aria-hidden="true"></i></button>
        </form>
    </div>
</div>

<!-- ##### Header Area Start ##### -->
<header class="header-area">

    <!-- Navbar Area -->
    <div class="faith-main-menu">
        <div class="classy-nav-container breakpoint-off">
            <div class="container">
                <!-- Menu -->
                <nav class="classy-navbar justify-content-between" id="faithNav">

                    <!-- Logo -->
                    <a class="nav-brand" href=""><img src="../../assets/img/mntLogo.jpg" alt="" style="font-family: Cabin, Helvetica, Arial;" class="img-circle d-none d-sm-inline">Mount Crawford</a>

                    <!-- Navbar Toggler -->
                    <div class="classy-navbar-toggler">
                        <span class="navbarToggler"><span></span><span></span><span></span></span>
                    </div>

                    <!-- Menu -->
                    <div class="classy-menu">

                        <!-- Close Button -->
                        <div class="classycloseIcon">
                            <div class="cross-wrap"><span class="top"></span><span class="bottom"></span></div>
                        </div>

                        <!-- Nav Start -->
                        <div class="classynav">
                            <ul>

                                <li><a routerLink="/bids">Bids ({{bidCount}})</a></li>
                              <li><a href="#">Announcements ({{totalAnnouncements}})</a>
                                <ul class="dropdown" style="width:200px;">
                                  <li><a routerLink="/events">Events ({{eventCount}})</a></li>
                                  <li><a routerLink="/hearings">Hearings ({{hearingsCount}})</a></li>
                                  <li><a routerLink="/news">News</a></li>
                                  <li><a routerLink="/announcements">Notices ({{noticeCount}})</a></li>
                                </ul>
                              </li>
                                <li><a href="#">Services</a>
                                    <ul class="dropdown">
                                        <li><a routerLink="services/emergency">Emergency Services</a></li>
                                        <li><a routerLink="services/facilities">Reserving Facilities</a></li>
                                        <li><a routerLink="services/trash">Trash Service</a></li>
                                        <li><a routerLink="/services/water">Water &#38; Sewer</a></li>
                                        <li><a href="https://mountcrawford.info/">Payment Portal</a></li>

                                    </ul>
                                </li>
                                <li><a href="#">Reference</a>
                                    <ul class="dropdown dropdownWide" style="width:200px;">
                                        <li><a href="../../assets/documents/2023-2024-BUDGET.pdf" target="_blank">Budget</a></li>
                                        <li><a routerLink="references/building">Building &#38; Construction</a></li>
                                        <li><a routerLink="references/licenses">Business Licenses</a></li>
                                        <li><a routerLink="references/ordinances">Codes &#38; Ordinances</a></li>
                                        <li><a href="../../assets/documents/Comprehensive_Plan.pdf" target="_blank">Comprehensive Plan</a></li>
                                        <li><a routerLink="references/maps">Maps</a></li>
                                        <li><a routerLink="references/property_tax">Personal Property Taxes</a></li>
                                        <li><a routerLink="references/real_estate_tax">Real Estate Taxes</a></li>
                                      <li><a href="../../assets/documents/Urban_dev_eval.pdf" target="_blank">Urban Development Eval</a></li>
                                    </ul>
                                </li>
                                <li><a href="#">Directory</a>
                                    <ul class="dropdown" style="width:200px;">
                                        <li><a routerLink="directory/board">Boards &#38; Commissions</a></li>
                                        <li><a  routerLink="directory/council">Town Council</a></li>
                                        <li><a  routerLink="directory/administration">Town Administration</a></li>
                                    </ul>
                                </li>

                            </ul>

                            <!-- Donate Button -->
                            <div class="donate-btn">
                                <a href="">Home</a>
                            </div>

                        </div>
                        <!-- Nav End -->
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>

