<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Personal Property Taxes</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">

        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                <div class="col-12 col-md-7">
                    <h2>Personal Property Taxes</h2>
                
                    <ul style="padding-left:15px">
                        <li class="bulletList"> Personal Property Taxes are paid to Mt. Crawford on an annual basis.</li>
                        <li class="bulletList"> The Town's tax rate is $0.20 per $100 of assessed value. Assessments are determined by Rockingham County.</li>
                        <li class="bulletList"> Every vehicle that has been registered to a Mt. Crawford resident and that has been regularly garaged, stored,or parked in the Town of Mt. Crawford anytime during the tax year is subject to this tax..</li>
                        <li class="bulletList"> Personal Property Tax bills are due on January 31st and are typically mailed the month preceding the due date.</li>
                    
                    </ul>
                    <p>If you have questions or concerns regarding Mt. Crawford Personal Property Tax, please email <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a>
                    </p>
            
                </div>
                <div class="col-12 col-md-5">
                    <img src="../../../assets/img/town_w_mnt.jpg" alt="">
                </div>
            </div>
                
                
            </div>
        </div>



    </div>
</section>



