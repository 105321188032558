<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Building and Zoning</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">


        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                    <div class="col-12 col-md-12">
                        <img src="../../../assets/img/clear_sky_and_road.jpg" alt="">
                    </div>
                <div class="col-12 col-md-12">
                    <h2>Building, Construction, and Zoning</h2>
                    <p>Most building and construction projects require some kind of permission from the town before the project can begin.</p>

                    <p>Please complete this application and submit it via email to <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a> as the first step in obtaining a building permit.</p>
                    <div class="row">
                    <div class="col-12 col-md-4 col-sm-12">
                        <a  class="text-default btn btn-info" target="_blank" href="../../../assets/documents/zoning_application.pdf" width="800px" height="2100px"><i class="fa fa-download"></i> Zoning Application Form</a><br><br>
                        </div>
                        <div class="col-12 col-md-4 col-sm-12">
                        <a  class="text-default btn btn-info" target="_blank" href="../../../assets/documents/Mt%20Crawford%20Zoning%20Map.pdf" width="800px" height="2100px"><i class="fa fa-map"></i> Zoning Map </a><br><br>
                        </div>
                        <div class="col-12 col-md-4 col-sm-12">
                            <a  class="text-default btn btn-info" target="_blank" href="../../../assets/img/rates.png" width="800px" height="2100px"><i class="fa fa-industry"></i> Water Connection Fees/Rates </a><br><br>
                        </div>
                     </div>
                     <p>Also please view <a href="http://va811.com/">VA811.com</a> for more informations and before you dig </p>


                <p>
                    For all water and sewer projects, the Town&#39;s Water and Sewer Standards & Specifications must be used.
                </p>

                </div>

            </div>


            </div>
        </div>



    </div>
</section>


