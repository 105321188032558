
<section class="hero-area">
    <div class="hero-slides owl-carousel">
        <!-- Single Hero Slide -->
        <div class="single-hero-slide bg-img" style="background-image: url(../../assets/img/winter/fieldRoad.JPG);"><!--../../assets/img/bg-img/green.jpeg-->
            <div class="container h-100">
                <div class="row h-75 align-items-center">
                    <div class="col-12 col-lg-12">
                        <!-- Slides Content -->
                        <div class="hero-slides-content">
                            <!--<h3 data-animation="fadeInUp" data-delay="300ms">Let God guide your path</h3>-->
                            <h2 data-animation="fadeInUp" data-delay="500ms" class="welcome">Town of Mount Crawford</h2>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Event Button -->
<!--            <div class="next-event-btn" data-animation="bounceInDown" data-delay="900ms" style="top: 75%">-->
<!--                <div class="container">-->
<!--                    <div class="row">-->
<!--                        <div class="col-12 text-right">-->
<!--                            <a href="#bus" class="btn faith-btn active">New  Event: <br>Stuff the Bus</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>

        <!-- Single Hero Slide -->
        <div class="single-hero-slide bg-img" style="background-image: url(../../assets/img/winter/farmSnowRoad.JPG);"><!--../../assets/img/bg-img/mnt2.jpeg-->
            <div class="container h-100">
                <div class="row h-100 align-items-center justify-content-end">
                    <div class="col-12 col-lg-12">
                        <!-- Slides Content -->
                        <div class="hero-slides-content">
                            <h2 data-animation="fadeInUp" data-delay="500ms"class="welcome">Town of Mount Crawford</h2>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Event Button -->
            <!--<div class="next-event-btn" data-animation="bounceInDown" data-delay="900ms">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-right">
                            <a href="https://www.facebook.com/stpaulsumcstaunton" class="btn faith-btn active">Sunday Worship: 10:00 AM</a>
                        </div>
                    </div>
                </div>
            </div>-->
<!--            <div class="next-event-btn" data-animation="bounceInDown" data-delay="900ms" style="top: 75%">-->
<!--              <div class="container">-->
<!--                  <div class="row">-->
<!--                      <div class="col-12 text-right">-->
<!--                          <a href="#bus" class="btn faith-btn active">New  Event: <br>Stuff the Bus</a>-->
<!--                      </div>-->
<!--                  </div>-->
<!--              </div>-->
<!--            </div>-->
        </div>

        <!-- Single Hero Slide -->
        <div class="single-hero-slide bg-img" style="background-image: url(../../assets/img/bg-img/mnt3.jpeg);">
            <div class="container h-100">
                <div class="row h-100 align-items-center">
                    <div class="col-12 col-lg-12">
                        <!-- Slides Content -->
                        <div class="hero-slides-content">
                             <div class=clearfix><br><br><br><br><br><br><br><br>
                                <!--<h6 class="date" data-animation="fadeInUp" data-delay="100ms">October 4, 2020 @10 AM</h6>-->
                                <h2 data-animation="fadeInUp" class="welcome">Town of Mount Crawford</h2>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="next-event-btn" data-animation="bounceInDown" data-delay="900ms" style="top: 75%">-->
<!--              <div class="container">-->
<!--                  <div class="row">-->
<!--                      <div class="col-12 text-right">-->
<!--                          <a href="#bus" class="btn faith-btn active">New  Event: <br>Stuff the Bus</a>-->
<!--                      </div>-->
<!--                  </div>-->
<!--              </div>-->
<!--          </div>-->
        </div>
    </div>
</section>
<!--
<div class="alert alert-info" style="z-index: 100;" role="alert">
    <strong>Modified Hours of Town Hall Operation:</strong>
 <p>
            Due to the outbreak of COVID-19 Hours of operation may be modified without prior notice. I will be available by phone for any concerns (540)- 421 - 0856. You may also call to make a payment by credit card.
            </p>
            <p>
            I appreciate your cooperation during this time. Thank You!
            </p>
            <p>
                Libby Clark | Town Manager
            </p>
            <a href="https://us02web.zoom.us/j/89616908696?pwd=Y1F0bG9ndVhmNmh4SkVFMmtFR3RsQT09" target="blank" class="btn btn-info">Join Town Hall Meeting</a>
            <a href="https://us02web.zoom.us/j/81552574964?pwd=WEdNaWwyZ01mblppcXJXSTd4WXFLUT09" target="blank" class="btn btn-info">Join Planning Commission Meeting</a>
            <a href="../../assets/documents/Emergency%20Ordinance%20Procedure%20Electronic%20Meetings%20%20Hearings%20COVID-19.pdf" target="blank" class="btn btn-info">Emergency Ordinance</a>
            <a href="../../assets/documents/RESOLUTION%20ELECT%20PUBLIC%20MEETINGS%20HEARINGS%20COVID-19.pdf" target="blank" class="btn btn-info">Resolution of Electronic Meetings</a>
  <br>
  <strong>RFP Boat Launch Bid</strong>
  <p>
    <a href="https://drive.google.com/file/d/1mi2Ht92f_oElAQh6ezbhSQ58mR1Q9P02/view?usp=sharing">View PDF</a>
  </p>
</div>
-->
<!-- ##### Hero Area End ##### -->
<!--<section class="church-activities-area section-padding-100-0 darkbg" id="bus">-->
<!--  <div class="container">-->
<!--      <div class="row">-->
<!--          <div class="col-12">-->
<!--              <div class="section-heading text-center mx-auto">-->
<!--                  <h2 class="welcomeTitle">Stuff the Bus 2021</h2>-->
<!--                  <div class="clearfix"></div>-->
<!--                  <br><br>-->
<!--                  <h6>August 6th and 7th - 9am to 5pm</h6>-->
<!--                  <h6>August 8th - 1pm to 5pm</h6>-->
<!--                  <img src="../../assets/img/stuffTheBus.png">-->

<!--                </div>-->
<!--          </div>-->
<!--      </div>-->
<!--  </div>-->
<!--</section>-->
<!-- ##### About Area Start ##### -->
<section class="faith-about-area section-padding-100-0 white" style="background-color: #003030;">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-12">
                <div class="about-content" style="border-bottom: none">
                    <img src="../../assets/img/mntLogo-Lg.png" alt="" class="img-circle" style="border-radius: 50%;">

                </div>
            </div>
            <div class="col-lg-6 col-sm-12">
                <div class="about-content" style="border-bottom: none">

                    <br>
                    <h2 class="welcome">Welcome to Mount Crawford</h2>
                    <hr class="white" style="background-color: white;">
                    <h6 style="color: #fff;">Town of Mt.Crawford<br>
                        PO Box 187<br>
                        Mt. Crawford, VA 22841</h6>
                        <h6 class="white" style="color: white; text-decoration: none">townofmountcrawford@gmail.com | (540) 421-0856

                           <br><a href="directory/town_personnel.html#townHall" height="2100px" style="color: white; text-decoration: none">Town Hall | 779 S. Main Street</a></h6>
                    <br>


                </div>
            </div>
        </div>
    </div>
</section>
<!-- ##### About Area End ##### -->
<section class="faith-about-area section-padding-100-0 white" style="background-color: #003030;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <div class="about-content" style="border-bottom: none">

          <br>
          <h2 class="welcome">Events</h2>
          <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftownofmountcrawford%2F&tabs=timeline,events&width=800&height=1000&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=420275702288338" width="500" height="1000" style="border:none;overflow:hidden" scrolling="yes" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>



        </div>
      </div>
    </div>
  </div>
</section>
