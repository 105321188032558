<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Codes and Ordinances</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">

        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                <div class="col-12 col-md-6">
                    <h2>Codes and Ordinances</h2>
                    <p>Click on the links to view!</p>
                    <ul style="padding-left:15px">
                        <!--<li class="bulletList">  <a  class="text-primary" href="../../assets/documents/RFP Surveying.pdf" width="800px" height="2100px" target="_blank">RFP Surveying</a></li>-->
                        <li class="bulletList">
                            <a  class="text-primary" href="../../assets/documents/NOISE-ORDINANCE-2019.pdf" width="800px" height="2100px" target="_blank">Noise Ordinance</a>
                        </li>
                        <li class="bulletList">
                            <a  class="text-primary" href="../../assets/documents/Nuisance%20Ordinance.pdf" width="800px" height="2100px" target="_blank">Nuisance Ordinance</a>
                        </li>
                        <li class="bulletList">
                            <a  class="text-primary" href="../../assets/documents/SUBDIVISION_ORDINANCE.pdf" width="800px" height="2100px" target="_blank">Subdivision Ordinance</a>
                        </li>
                        <li class="bulletList">
                            <a  class="text-primary" href="../../assets/documents/ORDINANCE_SEWER_WATER_SERVICE.pdf" width="800px" height="2100px" target="_blank">Sewer and Water Service and rates Ordinance</a>
                        </li>
                        <li class="bulletList">
                            <a  class="text-primary" href="https://drive.google.com/file/d/1atpgfogEmcmUB3i43OVOsNoJWKfnIV72/view?usp=sharingf" width="800px" height="2100px" target="_blank">
                              Transient Tax Ordinance
                            </a>
                        </li>
                      <li class="bulletList">
                        <a  class="text-primary" href="https://drive.google.com/file/d/1yhp5vkiYmSYsm6GezLM5WCPeLCL41IhJ/view?usp=sharing" width="800px" height="2100px" target="_blank">
                          Transient Tax Form
                        </a>
                      </li>
                        <li class="bulletList">
                          <a  class="text-primary" href="../../assets/documents/Trash%20Ordinance.pdf" width="800px" height="2100px" target="_blank">Trash Ordinance</a>
                        </li>
                        <li class="bulletList">
                            <a  class="text-primary" href="../../assets/documents/ORDINANCE_173_WATER_SYSTEM_ORDINANCE.pdf" width="800px" height="2100px" target="_blank">Water System </a>
                        </li>
                        <li class="bulletList">
                            <a  class="text-primary" href="../../assets/documents/ZONING_ORDINANCES.pdf" width="800px" height="2100px" target="_blank">Zoning Ordinance</a>
                        </li>
                    </ul>

                    <h4>Further Questions</h4>
                    <p>If you have questions or concerns regarding Mt. Crawford Codes and Ordinances, please email <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a></p>


                </div>
                <div class="col-12 col-md-6">
                    <img src="../../../assets/img/town_w_mnt.jpg" alt="">
                </div>
            </div>


            </div>
        </div>



    </div>
</section>



