<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Reserving Facilities</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">

        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                <div class="col-12 col-md-12">
                    <h2>Mnt Crawford Ruritan Hall Rental Information</h2>

                    <h3>High Availability</h3>
                    <p>Friday &amp; Saturday 8:00 am &#45; Midnight </p>
                    <p>Sunday &#45; Thursday 8:00 am &#45; 10:00 pm</p>
                    <p><small>All Music/Bands/Noise ends at 10:00 pm</small></p>
                    <p><small>No Alcohol or Smoking in the Hall</small></p>
                    
                    <h3>Contact and More information:</h3>
                    <p>For more information or to make reservations, contact Chris

    Campbell<br> 540-438- 0213/540-908- 1580</p>
                
            
                </div>
            
            </div>
            
                
                
            </div>
        
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <img src="../../../assets/img/pavilion_1.jpg" alt="">
            </div>
            <div class="col-12 col-md-6">

                <img src="../../../assets/img/pavillion_2.jpg" alt="">
            </div>
            
        </div>
        <br>
            <br>



    </div>
</section>




