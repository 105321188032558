<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Real Estate Taxes</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">

        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                <div class="col-12 col-md-7">
                    <h2>Real Estate Taxes</h2>
                
                    <ul style="padding-left:15px">
                        <li class="bulletList"> Real Estate Tax is paid to Mt. Crawford on an annual basis.</li>
                        <li class="bulletList"> The Town's tax rate is $0.07 per $100 of county assessment value</li>
                        <li class="bulletList"> Real Estate Tax bills are due on January 31st and are typically mailed to property owners the month preceding the due date.</li>
                    
                    </ul>
                    <p>If you have questions or concerns regarding Mt. Crawford real estate Tax, please email <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a>
                    </p>
            
                </div>
                <div class="col-12 col-md-5">
                    <img src="../../../assets/img/town_w_mnt.jpg" alt="">
                </div>
            </div>
                
                
            </div>
        </div>



    </div>
</section>




