import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ordinances',
  templateUrl: './ordinances.component.html',
  styleUrls: ['./ordinances.component.css']
})
export class OrdinancesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
