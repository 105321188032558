<style>
    .bulletList {
        list-style: circle;
        padding: 5px;
        margin-left: 15px;
    }
    .connect-title:first-letter {
    text-transform:capitalize;
}
</style>
<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Water and Sewer Billing</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">

        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                <div class="col-12 col-md-6">
                    <h2>Water and Sewer Rates</h2>
                    <p>Water and sewer rates are assessed monthly as follows:</p>
                    <ul style="padding-left:15px">
                        <li class="bulletList">$24.47 for 1,000 gallons (minimum bill)</li>
                        <li class="bulletList">$0.75 per 100 gallons of water or part thereof used.</li>
                    </ul>
                    <p>Water meters are read by Rockingham County Public Works employees; however, water usage is billed through the town of Mt. Crawford. Water bills are due on or before the 1st of each month. Penalty charges for late payment are levied beginning on the 1st of the following month.</p>
                    <p>
                        Payments may be submitted to <br>
                        <strong>PO Box 187 in Mt. Crawford</strong><br>
                        or dropped in the town's drop box located left of the front doors of Town Hall 779 S Main Street. Water payments, can also be dropped at Town hall or made in person during open office hours.
                    </p>
                    <p>Payments can now be made online at our new <a href="https://mountcrawford.info" target="_blank">payment portal</a><a  class="text-default btn btn-info" href="https://mountcrawford.info" height="2100px"><i class="fa fa-paper-plane-o"></i> Payment Portal </a></p>
                </div>
                <div class="col-12 col-md-6">
                    <img src="../../../assets/img/waste_treatment.jpg" alt="">
                </div>
            </div>
                <h3 class="gray">Turning On Water Service</h3>
                <p class="text-default">To turn on water service in a new name for an existing water and sewer connection, please contact the Town Treasurer at <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a> or (540) 421-0856.</p>
                <p class="text-default">The following form will be required to process your request<br><br>
                    <a  class="text-default btn btn-info" target="blank" href="../../../assets/documents/Application MtCrawford.pdf" width="800px" height="2100px"><i class="fa fa-download"></i> Click To Download Form </a><br><br>
                    Please note that the application requires a signature of the property owner. In the case of an account being established for renters, signatures from both tenants and property owner are required.</p>

                <h3>New Service Connections</h3>
                <h4>Connection Fees</h4>
                <p>
                    To receive new Town water and/or sewer services, the property owner is responsible to pay the connection fees.  Paying the connection fees establishes the property owner's rights to connect onto the town&#39;s water and/or sewer system.  The town will not install the service line(s) to the property until these fees are paid.  The connection fees are assessed based on the rates below. Please note that a typical residential connection requires a 3/4 meter.
                </p>
                <img src="../../../assets/documents/water-connection-chart.png" height="400" width="700" alt="rates">
                <h4>Additional Costs</h4>
                <p>
                The property owner is also responsible for installing the service lines from the property line, where the town stops the service lines, to the building that will receive the water and/or sewer service.  Usually this is performed by an independent contractor, licensed by the state to perform such tasks.  The property owner will hire the contractor of their choosing and make the arrangements to pay their fees.
                </p>
                <h4>Application</h4>
                <p>
                To begin the process of receiving new Town water and/or sewer services, please email <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a>
                </p>

                <h4>Further Questions</h4>
                <p>If you have questions or concerns regarding Mt. Crawford Water and Sewer, please email <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a></p>

            </div>
        </div>



    </div>
</section>

