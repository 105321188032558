<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Trash Services</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">

        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                <div class="col-12 col-md-6">
                    <h2>Household Trash</h2>
                    <ul style="padding-left:15px">
                        <li class="bulletList">  All residents in Mt. Crawford are serviced by Mt. Crawford's contract with Waste Management.</li>
                        <li class="bulletList">  Trash collection is weekly on Fridays</li>
                        <li class="bulletList">  In inclement weather (snow, etc), trash will be collected the next working day</li>
                        <li class="bulletList">  Billing for trash services are included in the monthly water/sewer bill. The current rate is $12.50 per month.</li>
                    
                    </ul>
                    <p>
                        To request a Waste Management issued trash can or if you have any questions pertaining to trash collection, please email <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a>
                    </p>
                    <h4>Further Questions</h4>
                    <p>If you have questions or concerns regarding Mt. Crawford Personal Trash Services, please email <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a></p>
            
                </div>
                <div class="col-12 col-md-6">
                    <img src="../../../assets/img/swings_zoomedout.jpg" alt="">
                </div>
            </div>
                
                
            </div>
        </div>



    </div>
</section>



