<div class="" style="background-color: #003030; height: 200px">
  <div class="container h-100">
    <div class="row h-100 align-items-center">
      <div class="col-12 col-md-12">
        <div class="breadcumb-text">
          <h1 class="connect-title title">News Letters</h1>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="ministries-area section-padding-50-0">
  <div class="container">

    <div class="row">

      <div class="col-12 col-md-12 single-ministry mb-100">
        <div class="row">
          <div class="col-12 col-md-6">
            <h2>Current News</h2>
            <p>Click on the links to view!</p>
            <ul style="padding-left:15px">
              <li class="bulletList">
                <a  class="text-primary" href="https://drive.google.com/file/d/1NORz6h4fOXQgqWF4-JeTm9VAMjiTV39O/view?usp=share_link" width="800px" height="2100px" target="_blank">
                  February 2023</a>
              </li>

            </ul>

          </div>
          <div class="col-12 col-md-6">
            <img src="../../assets/img/town_w_mnt.jpg" alt="">
          </div>
          <div class="col-12 col-md-12">
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftownofmountcrawford%2F&tabs=timeline&width=800&height=1000&small_header=false&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=420275702288338" width="800" height="1000" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
          </div>
        </div>


      </div>
    </div>



  </div>
</section>



