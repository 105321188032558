<!-- ##### Footer Area Start ##### -->
<footer class="footer-area">
    <!-- Main Footer Area -->
    <div class="main-footer-area section-padding-100-0 bg-img foo-bg-overlay" style="background-image: url('assets/img/bg-img/green.jpeg');">
        <div class="container">
            <div class="row">

                <div class="col-12 col-sm-12 col-xl-12">
                    <div class="footer-widget text-center">
                        <div class="widget-title col-xl-12" style="text-align: center" >
                            <h6>Contact Us</h6>
                        </div>
                        <div class="row" id="contact">
                            <!-- Single Contact Area -->
                            <div class="single-contact-area col-lg-3 col-md-12 col-sm-12 ">
                                <p>Address:</p>
                                <span>PO Box 187 <br>Mt. Crawford, VA 22841 </span>
                            </div>

                            <!-- Single Contact Area -->
                            <div class="single-contact-area col-lg-3 col-md-12 col-sm-12">
                                <p>Phone / Emil</p>
                                <span>(540) 421-0856 <br> townofmountcrawford@gmail.com </span>

                            </div>

                            <!-- Single Contact Area -->
                            <div class="single-contact-area col-lg-3 col-md-12 col-sm-12">
                                <p>Social Media:</p>
                                <a href="https://www.facebook.com/townofmountcrawford/" target="_blank"><span> Facebook <small>(click)</small></span> </a>
                            </div>
                            <!-- Single Contact Area -->
                            <div class="single-contact-area col-lg-3 col-md-12 col-sm-12">
                                <p>Office Hours:</p>
                                <span>Tuesday and Thursday (10am - 5pm)</span>
                            </div>
                            <div class=clearfix></div>
                            <div class="col col-lg-12"><hr style="border-top:1px solid white;"></div>

                            <div class="col col-lg-12">
                                <p><small>The information contained on this page is for general purposes only and may not contain the most current information. For answers to more specific questions or concerns, or to receive the latest information possible, please call (540) 421-0856</small></p>
                                <p><small>&copy;Town of Mt. Crawford | PO Box 187 | Mt. Crawford, VA 22841 | townofmountcrawford@gmail.com | (540) 421-0856 </small></p>
                                <p><small>Web Designed by Parker Dell | 2016 </small></p>
                            </div>


                            <div class="col col-lg-4">

                            </div>
                            <div class="col-lg-4 text-center col-lg-offset-4">
                                <div>
                                    <img src="../../assets/img/mntLogo-Lg.png" alt="" class="img-circle" style="border-radius: 50%;">
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    </div>
</footer>


