<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Emergency Services</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">

        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                <div class="col-12 col-md-6">
                
                    <p>Mt. Crawford is patrolled by Bridgewater Police Services. The Bridgewater Police Department can be contacted using the contact information below. However, in the case of an emergency, please call 9-1-1.</p>
                   <p>Bridgewater Police Department - (540) 828-2611 or (540) 828-4674</p>
            
                </div>
                <div class="col-12 col-md-6">
                    <img src="../../../assets/img/town_limit_sign.jpg" alt="">
                </div>
            </div>
                
                
            </div>
        </div>



    </div>
</section>



