<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Town Maps</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">

        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                <div class="col-12 col-md-6">
                    <h2>Town Maps</h2>
                    <p>Click on the links to view!</p>
                    <ul style="padding-left:15px">
                        <li class="bulletList">  <a  class="text-primary" href="../../assets/documents/Town%20of%20Mt%20Crawford%20map.pdf" width="800px" height="2100px" target="_blank"><i class="fa fa-map"></i> Town of Mt. Crawford</a></li>
                        <li class="bulletList">  <a  class="text-primary" href="../../assets/documents/Mt.%20Crawford%20Street%20Map.pdf" width="800px" height="2100px" target="_blank"> <i class="fa fa-map-pin"></i> Town of Mt. Crawford Street map </a></li>
                        <li class="bulletList">  <a  class="text-primary" href="../../assets/documents/Mt%20Crawford%20Zoning%20Map.pdf" width="800px" height="2100px" target="_blank"> <i class="fa fa-map-o"></i> Town of Mt. Crawford Zoning map </a></li>
                    
                    </ul>
                
            
                </div>
                <div class="col-12 col-md-6">
                    <img src="../../../assets/img/town_w_mnt.jpg" alt="">
                </div>
            </div>
                
                
            </div>
        </div>



    </div>
</section>


