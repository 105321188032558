import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-tax',
  templateUrl: './property-tax.component.html',
  styleUrls: ['./property-tax.component.css']
})
export class PropertyTaxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
