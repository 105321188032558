<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Announcements / Upcoming Meetings</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">

        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                <div class="col-12 col-md-6">
                    <h2>{{title}}</h2>

                    <ul style="padding-left:15px">
                      <li class="bulletList" *ngFor="let event of events">
                        <h5 class="text-primary">{{event.name}}</h5>
                        <p *ngIf="event.date != ''">{{event.date}}</p>
                        <div *ngFor="let p of event.text">
                          <p>{{p}}</p>
                        </div>
                        <div style="display: flex">
                          <div *ngFor="let btn of event.buttons" style="margin-left: 3px">
                            <a [href]="btn.link" target="_blank" [class]="'btn btn-'+btn.color">{{btn.title}}</a>
                          </div>
                        </div>

                      </li>
                    </ul>

                </div>
                <div class="col-12 col-md-6">
                    <img src="../../../assets/img/town_w_mnt.jpg" alt="">
                </div>
            </div>


            </div>
        </div>



    </div>
</section>



