<div class="" style="background-color: #003030; height: 200px">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-12 col-md-12">
                <div class="breadcumb-text">
                    <h1 class="connect-title title">Business License</h1>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="ministries-area section-padding-50-0">
    <div class="container">

        <div class="row">

            <div class="col-12 col-md-12 single-ministry mb-100">
                <div class="row">
                <div class="col-12 col-md-7">
                    <h2>Business License</h2>
            
                    <ul style="padding-left:15px">
                        <li class="bulletList">  A business license is required to operate a business in Mt. Crawford. Therefore, any person engaged in a business within the Town of Mt. Crawford must obtain a business license. This includes all phases of business, profession, trade, occupation or rentals conducted in the Town.
                        </li>
                        <li class="bulletList">A flat fee of $25.00 is assessed for an annual license.</li>
                        <li class="bulletList">Business license applications are mailed to all existing businesses in the month preceding the renewal date of May 15th.</li>
                    
                    </ul>
                    <p>To obtain a new business license please email <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a>
                        <a  class="text-default btn btn-info" target="_blank" href="../../../assets/documents/Business%20License%20Application.pdf" width="800px" height="2100px"> Download <i class="fa fa-download" aria-hidden="true"></i></a><br><br>
                    </p>
                    <h4>Further Questions</h4>
                    <p>If you have questions or concerns regarding Mt. Crawford business licenses, please email <a href="mailto:townofmountcrawford@gmail.com">townofmountcrawford@gmail.com</a></p>
                    
            
                </div>
                <div class="col-12 col-md-5">
                    <img src="../../../assets/img/town_w_mnt.jpg" alt="">
                </div>
            </div>
                
                
            </div>
        </div>



    </div>
</section>


